<!--
 * @Description: AI智能客服
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-14 14:14:41
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-12-03 20:27:49
-->
<template>
    <div class="flex-col bg-f5f7fc">
        <header-nav />
        <div class="container-full flex-col bg-1">
            <div class="container h-full flex-col items-start justify-start">
                <span class="fs-36 fc-main mt-160 -ml-25"><span class="fc-primary">「 </span>智慧服务  无缝连接  全程陪伴<span class="fc-primary"> 」</span></span>
                <span class="fs-24 fc-sub mt-30">—— 新一代 “ 全渠道智能客服平台 ” </span>
                <a href="#reg" class="primary shadow text-center w-140 h-40 mt-120">申请试用</a>
            </div>
        </div>
        <div class="container-full flex-col bg-2">
            <div class="container flex-col items-center">
                <span class="mt-80 fs-36">产品<span class="fc-primary">介绍</span></span>
                <span class="fc-sub fs-24 mt-20">红熊全场景一体化智能客服，通过多元化的沟通渠道和智能化的服务流程，为客户提供全方位服务体验</span>
            </div>
            <div class="container">
                <div class="flex-row-wrap mt-65 justify-between">
                    <div class="product-box flex-col items-start justify-start text-left mt-15" v-for="(item,index) in productList" :key="index">
                        <img :src="item.iconUrl" class="icon-28" />
                        <span class="fs-18-fat-0 fc-main mt-15">{{item.title}}</span>
                        <span class="fc-gray-100 fs-14 mt-15">{{item.content}}</span>
                    </div>
              </div>
            </div>
        </div>
        <div class="container-full bg-3 p-r flex-col">
            <div class="container flex-col items-center">
                <span class="mt-80 fs-36">产品<span class="fc-primary">功能</span></span>
            </div>
            <div class="container flex-col">
                <div class="flex-row mt-60 items-center justify-between">
                    <div class="fn-box-1" v-for="(text,index) in fnText" :key="index">
                        <span class="fc-primary fs-14-fat">{{text}}</span>
                    </div>
                </div>
                <div class="flex-row mt-20 justify-between fn-box-2">
                    <div class="flex-row items-center justify-between">
                        <div class="fn-box-2-1">
                            <span class="fs-14-fat">客户</span>
                        </div>
                        <img :src="$IMG_PUBLIC_PATH_QM + '/jt-large%402x.png'" class="fn-jt-l -ml-12" />
                        <div class="flex-col items-center justify-center fn-box-2-2">
                            <span class="text-box fc-main fs-14 " v-for="(text,index) in fnText2" :key="index">
                                {{text}}
                            </span>
                        </div>
                        <div class="flex-col justify-center items-center plr-25">
                            <span class="text-v fc-primary">智能接入</span>
                            <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt" />
                            <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt transform-180 mt-70" />
                            <span class="text-v fc-primary">智能输出</span>
                        </div>
                        <div class="flex-col mt-20">
                            <div class="fn-box-2-3 w-660 plr-20">
                                <div class="fn-title-box -mt-18">
                                    <span class="fc-white fs-16">红熊全场景一体化智能客服平台</span>
                                </div>
                                <div class="flex-row items-center">
                                    <div class="flex-col items-center mt-30 ml-20">
                                        <img :src="$IMG_PUBLIC_PATH_QM  + '/rb-robit%402x.png'" class="icon-48" />
                                        <span class="fc-sub mt-20">AI机器人</span>
                                    </div>
                                    <div class="flex-col items-center ml-40">
                                        <span class="fn-white-box w-134 text-center fc-main mt-20">智能文本机器人</span>
                                        <span class="fn-white-box w-134 text-center fc-main mt-8">智能呼入机器人</span>
                                        <span class="fn-white-box w-134 text-center fc-main mt-8">智能呼入机器人</span>
                                    </div>
                                    <div class="flex-col items-center ml-70">
                                        <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt mt-30" />
                                        <span class="fc-primary mt-20">智能转人工</span>
                                    </div>
                                    <div class="flex-col items-center mt-30 ml-50">
                                        <img :src="$IMG_PUBLIC_PATH_QM  + '/manual-seat%402x.png'" class="icon-48" />
                                        <span class="fc-sub mt-20">人工坐席</span>
                                    </div>
                                    <div class="flex-col items-center ml-40">
                                        <span class="fn-white-box w-92 text-center fc-main mt-20">人机耦合</span>
                                        <span class="fn-white-box w-92 text-center fc-main mt-8">坐席辅助</span>
                                        <span class="fn-white-box w-92 text-center fc-main mt-8">坐席辅助</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-row w-660 items-center justify-center mt-20 h-28">
                                <span class="fc-primary">以通话数据为依据</span>
                                <img :src="$IMG_PUBLIC_PATH_QM + '/jt-tb%402x.png'" class="icon-jt-tb ml-116" />
                                <span class="fc-primary">以客户信息为中心</span>
                            </div>
                            <div class="flex-row items-center mt-40">
                                <div class="flex-col fn-box-2-3 w-380 plr-20">
                                    <div class="fn-title-box -mt-18"> <span class="fc-white fs-16">人工坐席</span></div>
                                    <div class="flex-row-wrap justify-between pt-12">
                                        <span class="fn-white-box w-100 text-center fc-main mt-8" v-for="(text,i) in fnText3" :key="i">{{text}}</span>
                                    </div>
                                </div>
                                <div class="flex-col fn-box-2-3 w-260 ml-20 plr-20">
                                    <div class="fn-title-box -mt-18"> <span class="fc-white fs-16">AI机器人</span></div>
                                    <div class="flex-row-wrap justify-between pt-12">
                                        <span class="fn-white-box w-100 text-center fc-main mt-8" v-for="(text,i) in fnText4" :key="i">{{text}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-col justify-center items-center plr-25">
                            <span class="text-v fc-primary">查询办理</span>
                            <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt" />
                            <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt transform-180 mt-70" />
                            <span class="text-v fc-primary">数据同步</span>
                        </div>
                        <div class="flex-col items-center justify-center fn-box-2-2 w-152 mt-10 plr-20">
                            <div class="fn-title-box w-full -mt-28"> <span class="fc-white fs-16">系统对接</span></div>
                            <span class="fn-white-box w-100 text-center fc-main" :class="i == 0 ? 'mt-10' : 'mt-40'" v-for="(text,i) in fnText5" :key="i">{{text + i}}</span>
                        </div>
                    </div>
                </div>
                <div class="mt-20 fn-box-2-3 h-55 w-full items-center justify-center">
                    <span class="fs-14-fat fc-primary">全方位的沟通渠道 / 无微不至的客户关怀 / 无比快捷的协同互动</span>
                </div>
                <div class="flex-row items-center justify-between mt-20">
                    <div class="flex-row items-center justify-center w-320 h-55 bg-c-primary rounded-8">
                        <span class="fc-white fs-14-fat">分阶段建设/平滑过渡</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt" />
                    <div class="flex-row items-center justify-center fn-box-2-3 w-200 h-55">
                        <span class="fc-primary fs-14-fat">话务平台</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt" />
                    <div class="flex-row items-center justify-center fn-box-2-3 w-200 h-55">
                        <span class="fc-primary fs-14-fat">在线客服</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-lr%402x.png'" class="icon-jt" />
                    <div class="flex-row items-center justify-center fn-box-2-3 w-200 h-55">
                        <span class="fc-primary fs-14-fat">AI机器人</span>
                    </div>
                </div>
            </div>
        </div>
        <regbox-footer :type="'try'" id="reg"/>
        <airb-footer class="mt-60"/>
    </div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'
import {IMG_PUBLIC_PATH_QM} from '@/utils/constants'
const productList = [
    {
        title:'全渠道接入',
        templateName: '1',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-join-icon%402x.png',
        content:'通过整合多种渠道，实现客户与企业的无缝连接，提升客户服务的可达性和便利性'
    },
    {
        title:'智能化服务',
        templateName: '2',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-ai-icon%402x.png',
        content:'利用AI机器人和智能呼入/呼出技术，提供自动的客户服务，减少人工干预，提高效率'
    },
    {
        title:'知识库与数据流转',
        templateName: '3',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-dataset-icon%402x.png',
        content:'通过AI大模型的支持，实现知识最大化复用和数据的高效流转，促进业务创新'
    },
    {
        title:'全生命周期服务',
        templateName: '4',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-all-icon%402x.png',
        content:'内置智能知识库，帮助企业快速培养专业的AI运维团队，提供从咨询到售后的全生命周期服务'
    },
    {
        title:'人机协作',
        templateName: '5',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-robit-icon%402x.png',
        content:'在人工坐席与AI机器人之间实现智能转接，确保复杂问题得到专业人工服务的及时响应'
    },
    {
        title:'系统对接',
        templateName: '6',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-system-icon%402x.png',
        content:'与呼叫系统、工单系统、CRM系统等平台进行数据同步，实现信息无缝对接和自动化'
    },
    {
        title:'客户体验',
        templateName: '7',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-start-icon%402x.png',
        content:'通过多项先进功能，提升客户服务的个性化和满意度'
    },
    {
        title:'实时数据分析',
        templateName: '8',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/service-data-icon%402x.png',
        content:'通过智能数据分析与可视化仪表盘，实时监控客户服务质量和关键指标，帮助企业快速优化服务策略'
    }
] 

export default {
    components:{
        headerNav,airbFooter,regboxFooter
    },
    data(){
        return{
            productList,
            fnText:['全渠道统一接入','机器人智能接待','知识最大化复用','数据自动流转','全生命周期服务闭环'],
            fnText2:['固定电话','手机','视频/数字人','短信','邮件','网站','微信','微博','APP','电商'],
            fnText3:['来电弹屏','客户资料','工单录入','坐席管理','留言表单','智能语音','大屏数据','统计报表','系统监控'],
            fnText4:['多轮对话','场景话术','知识图谱','热点分析','智能质检','用户画像'],
            fnText5:['呼叫系统','工单系统','CRM系统','知识库系统','业务系统','业务系统']
        }
    }
}
</script>
<style lang="less" scoped>
   .bg-4{
        width: 100%;
        height: 800px;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiT-bg-4%402x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top left;
        .bg-c{
            height: 500px;
            background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiT-bg-c%402x.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: top left;
            .c-box{
                width: 25%;
                padding-left: 40px;
                height: 300px;
            }
        }
    }
    .bg-3{
        width: 100%;
        height: 1000px;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiS-bg-3%402x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top left;
    }
    .w-92{
        width: 92px;
    }
    .w-100{
        width: 100px;
    }
    .w-134{
        width: 134px;
    }
    .w-152{
        width: 152px !important;
    }
    .w-260{
        width: 260px;
    }
    .w-380{
        width: 380px;
    }
    .w-660{
        width: 660px;

    }
    .fn-white-box{
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EBEBEB;
    }
    .h-55{
        height: 55px !important;
        line-height: 55px;
    }
    .fn-box-2-3{
        height: 198px;
        background: #F5F7FC;
        border-radius: 8px;
    }
    .fn-title-box{
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #155EEF;
        border-radius: 20px 0px 20px 0px;   
    }
    .h-28{
        height: 28px;
    }
    .icon-jt{
        width: 28px;
        height: 60px;
    }
    .icon-jt-tb{
        width: 60px;
        height: 28px;
    }
    .text-v{
        writing-mode: vertical-rl; // 设置文本方向为从右到左的垂直方向
        text-orientation: upright; // 保持字符直立
        letter-spacing: 0.3em;
    }
    .fn-jt-l{
        width: 53px;
        height: 267px;
    }
    .fn-box-2-2{
        box-sizing: border-box;
        width: 128px;
        height: 500px;
        background: #F5F7FC;
        border-radius: 8px;
        .text-box{
            box-sizing: border-box;
            margin-top: 8px;
            width: 100px;
            height: 40px;
            line-height: 40px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #EBEBEB;
        }
    }
    .fn-box-2-1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 500px;
        background: #155EFF;
        border-radius: 8px;
        writing-mode: vertical-rl; // 设置文本方向为从右到左的垂直方向
        text-orientation: upright; // 保持字符直立
        letter-spacing: 0.3em;
        color: #FFFFFF;
        text-align: center;
    }
    .fn-box-2{
        height: 500px;
    }
    .fn-box-1{
        width: 227px;
        height: 55px;
        line-height: 55px;
        background: #F5F7FC;
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        
    }
    .product-box{
        width: 288px;
        height: 203px;
        padding: 30px;
        background: #FFFFFF;
        border-radius: 8px;
    }
    .bg-2{
        height: 800px;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiS-bg-2%402x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top left;
    }
    .bg-1{
        height: 600px;
        background: #DDE6ED;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiS-bg%402x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
    }
</style>
