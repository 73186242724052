/*
 * @Description: 常量数据
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 14:26:09
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-12-09 12:27:12
 */
export const IMG_PUBLIC_PATH = 'https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/aiModel/'
export const AIPRB_IMG_PATH= 'https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com'
export const IMG_PUBLIC_PATH_QM = 'https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming'