<!--
 * @Description: AI数据集管理
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-14 14:14:41
 * @LastEditors: yujiangping
 * @LastEditTime: 2024-12-03 20:28:43
-->
<template>
    <div class="flex-col bg-f5f7fc">
        <header-nav />
        <div class="container-full flex-col bg-1">
            <div class="container h-full flex-col items-start justify-start">
                <span class="fs-36 fc-main mt-160 -ml-25"><span class="fc-primary">「 </span>驾驭未来，智绘交通 <span class="fc-primary"> 」</span></span>
                <span class="fs-24 fc-sub mt-30">—— 您的自动驾驶AI数据管理专家！</span>
                <a href="#reg" class="primary shadow text-center w-140 h-40 mt-120">申请试用</a>
            </div>
        </div>
        <div class="container-full flex-col bg-2">
            <div class="container flex-col items-center">
                <span class="mt-80 fs-36">产品<span class="fc-primary">介绍</span></span>
                <span class="fc-sub fs-18 mt-20">为自动驾驶提供丰富的训练数据，提升模型对多样化交通场景的适应能力，降低数据采集成本，增强模型的泛化性能</span>
            </div>
            <div class="container">
                <div class="flex-row mt-80 justify-between">
                    <div class="product-box flex-col items-start justify-start text-left mt-15" v-for="(item,index) in productList" :key="index">
                        <img :src="item.iconUrl" class="icon-44" />
                        <span class="fs-20 fc-main mt-40">{{item.title}}</span>
                        <span class="fc-gray-100 fs-16-thin mt-40">{{item.content}}</span>
                    </div>
              </div>
            </div>
        </div>
        <div class="container-full bg-3 p-r flex-col">
            <div class="container flex-col items-center">
                <span class="mt-80 fs-36">产品<span class="fc-primary">功能</span></span>
            </div>
            <div class="container bg-3-1 mt-80">
                <div class="flex-row justify-between items-center">
                    <div class="flex-col justify-center">
                        <img :src="$IMG_PUBLIC_PATH_QM + '/aiDS-fn-1%402x.png'" class="fn-img-size" />
                        <span class="fs-14-500 fc-primary mt-20">海量原始图片/视频</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-l-40%402x.png'" class="icon-jt mlr-10" />
                    <div class="flex-col justify-center">
                        <img :src="$IMG_PUBLIC_PATH_QM + '/aiDS-fn-2%402x.png'" class="fn-img-size" />
                        <span class="fs-14-500 fc-primary mt-20">人工标注少量的训练数据集</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-r-long%402x.png'" class="icon-jt-l mlr-10" />
                    <div class="flex-col justify-center">
                        <img :src="$IMG_PUBLIC_PATH_QM + '/aiDS-fn-3%402x.png'" class="fn-img-size" />
                        <span class="fs-14-500 fc-primary mt-20">VSI模型训练</span>
                    </div>
                </div>
                <div class="flex-row items-center mt-140">
                    <div class="flex-col h-210 items-start">
                        <div class="fn-box w-340 h-150">
                            <div class="fn-title-box"><span>自动标注需求</span></div>
                            <div class="flex-row-wrap justify-between">
                                <span class="text-box" :class="i<3 ? 'mt-10' : 'mt-8'" v-for="(text,i) in fnText" :key="i">{{text}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mlr-10">
                        <img :src="$IMG_PUBLIC_PATH_QM + '/jt-l-40%402x.png'" class="icon-jt" />
                        <img :src="$IMG_PUBLIC_PATH_QM + '/jt-l-40%402x.png'" class="icon-jt mt-100" />
                    </div>
                    <div class="flex-col">
                        <div class="fn-box flex-col items-start w-306 h-90 p-r">
                            <span class="fs-14-500 fc-main">形成型模型</span>
                            <span class="fs-14-500 fc-sub mt-10">如：红绿灯、障碍物</span>
                            <img :src="$IMG_PUBLIC_PATH_QM + '/jt-round%402x.png'" class="jt-size" />
                        </div>
                        <div class="fn-box flex-col items-start w-306 h-110 mt-16">
                            <span class="fs-14-500 fc-main">已支持模型</span>
                            <span class="fs-14-500 fc-sub mt-10 text-left">机动车识别、区域闯入、机动车违停、道路可行驶区域</span>
                        </div>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-l-40%402x.png'" class="icon-jt mlr-10" />
                    <div class="fn-box flex-col items-center justify-center bg-c-primary w-120 h-75">
                        <span class="fc-white fs-14">VSI工作流</span>
                        <span class="fc-white fs-14">编辑器</span>
                    </div>
                    <img :src="$IMG_PUBLIC_PATH_QM + '/jt-l-40%402x.png'" class="icon-jt mlr-10" />
                    <div class="fn-box flex-col items-center justify-center border-primary w-120 h-75">
                        <span class="fc-primary fs-14">VSI分析引擎</span>
                    </div>
                </div>
            </div>
        </div>
        <regbox-footer :type="'try'" id="reg"/>
        <airb-footer class="mt-60"/>
    </div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'
import {IMG_PUBLIC_PATH_QM} from '@/utils/constants'
const productList = [
    {
        title:'数据集管理平台',
        templateName: '1',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/ds-platform-icon%402x.png',
        content:'开发一个管理道路场景的时空数据集的平台，功能包括数据清洗、标注管理和数据集的组织与存储。平台需要能够处理图片和视频格式的数据，同时对其中包含的道路元素、道路参与者、事件等进行全面管理'
    },
    {
        title:'时空大模型',
        templateName: '2',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/ds-join-icon@2x.png',
        content:'设计和训练一个针对道路场景的时空大模型，该模型可以生成合成数据，捕捉道路要素、交通参与者、天气变化等多方面的复杂动态信息，生成稳定而准确的图片和视频'
    },
    {
        title:'合成数据平台',
        templateName: '3',
        iconUrl:IMG_PUBLIC_PATH_QM + '/icons/ds-join-icon@2x.png',
        content:'构建一个合成数据平台，用户可以灵活指定场景、道路要素、天气、道路参与者、事件等进行图片和视频生成，同时为生成内容提供详细的标注，支持智能驾驶系统的训练'
    }
] 

export default {
    components:{
        headerNav,airbFooter,regboxFooter
    },
    data(){
        return{
            productList,
            fnText:['车道线','道路设施','车辆','绿化植被','行人','…'],
        }
    }
}
</script>
<style lang="less" scoped>
    .bg-3{
        width: 100%;
        height: 1005px;
        background: #FFFFFF;
        // background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/aiS-bg-3%402x.png");
    }
    .bg-3-1{
        height: 675px;
        background: #F5F7FC;
        border-radius: 16px;
        border: 1px solid #EBEBEB;
        padding: 60px;
    }
    .fn-img-size{
        width: 300px;
        height: 180px;
    }
    .jt-size{
        position: absolute;
        right: -117px;
        bottom: 40px;
        width: 107px;
        height: 200px;
    }
    .icon-jt{
        width: 40px;
        height: 9px;
    }
    .icon-jt-l{
        width: 100px;
        height: 10px;
    }
    .icon-jt-tb{
        width: 60px;
        height: 28px;
    }
    .fn-title-box{
        width: 100%;
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: #155EEF;
        border-radius: 20px 0px 20px 0px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: -28px;
    }
    .w-340{
        width: 340px;
    }
    .h-150{
        height: 150px;
    }
    .w-306{
        width: 306px;
    }
    .h-210{
        height: 182px;
    }
    .h-110{
        height: 110px;
    }
    .h-90{
        height: 90px;
    }
    .w-120{
        width: 120px;
    }
    .h-75{
        height: 75px;
    }
    .border-primary{
        border:1px solid #155EEF
    }
    .text-box{
        width: 94px;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EBEBEB;
    }
    .fn-box{
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px;
    }
    .product-box{
        width: 380px;
        height: 424px;
        background: #FFFFFF;
        box-shadow: 0px 8px 20px 0px rgba(32,58,122,0.06);
        border-radius: 0px 8px 40px 0px;
        border-left: 6px solid #155EEF;
        padding: 40px;
    }
    .bg-2{
        height: 800px;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/hybrid-bg-2%402x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top left;
    }
    .bg-1{
        height: 600px;
        background: #E4E9F4;
        background-image: url("https://redbear-static-img.oss-cn-hangzhou.aliyuncs.com/qinming/AIDS-bg.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
    }
</style>
